@use "variables.scss";

.unsupported {
  width: variables.$container-width;
  max-width: 100%;
  margin: 3em auto;
  padding: 0 variables.$container-padding;
}

.unsupported__inner {
  padding: 0.5em 0.75em;

  color: variables.$color-red-100;

  background: variables.$color-red-700;
  border-radius: variables.$border-radius;

  a {
    color: inherit;
  }
}
