// margin-top

.mt-3 {
  margin-top: 0.5em;
}

.mt-5 {
  margin-top: 1em;
}

.mt-8 {
  margin-top: 1.5em;
}

// margin-bottom

.mb-2 {
  margin-bottom: 0.25em;
}

.mb-3 {
  margin-bottom: 0.5em;
}

.mb-4 {
  margin-bottom: 0.75em;
}

.mb-5 {
  margin-bottom: 1em;
}

.mb-8 {
  margin-bottom: 1.5em;
}

// margin-left

.ml-6 {
  margin-left: 1.25em;
}

// margin-right

.mr-5 {
  margin-right: 1em;
}
