







































@use "../css/variables.scss";

.button {
  display: inline-block;
  padding: 0.5em 0.75em;

  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
  color: #fff;

  border-radius: variables.$border-radius;

  transition: background-color 0.15s;

  &.is-color-primary {
    background: variables.$color-primary;

    &:hover {
      background: variables.$color-primary-dark;
    }
  }

  &.is-color-secondary {
    background: variables.$color-gray-500;

    &:hover {
      background: variables.$color-gray-600;
    }
  }
}
