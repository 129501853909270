@use "variables.scss";

h1 {
  font-size: calc(1.25em + 1.5vw);
  line-height: 1.2;

  @media (min-width: variables.$container-width) {
    font-size: 2em;
  }
}

a {
  color: variables.$color-primary;
}

address {
  font-style: normal;
}
