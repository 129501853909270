












@use "../css/variables.scss";

.container {
  width: variables.$container-width;
  max-width: 100%;
  margin: 4em auto;
  padding: 0 variables.$container-padding;
}
